<template>
  <div class="row">
    <div class="panel" :style=panelStyle v-for="(item,i) in items" :key=i >
      <div class="panel-body">
        
        <div class="row" v-if="withTitle">
          <Input :as-title=true :size=11 name="title" v-model="items[i].title" :apiDomain=apiDomain :apiTopic=apiTopic :apiPath="item.entity" />
          <div class="col-md-1" >
            <button class="btn btn-secondary btn-sm" @click="delItem(i)">Удалить</button>
          </div>
        </div>
        
        <div class="row mt-3">
          <div class="col-md-12">
            <slot :parent=item ></slot>
            <Icon name="trash" v-if="!withTitle" @click="delItem(i)"/>
          </div>
        </div>
      
      </div>    
    </div>
    <div class="row">
      <button class="btn btn-primary btn-sm pull-right" @click=addItem style="margin-right: 3em" ><Icon name="plus" /> {{addText}}</button>
    </div>
  </div>
</template>

<script>

// Динамический список, куда можно добавлять новые элементы и удалять старые

import darsan from "darsan"
import Input from "common/visual/cooper/Input.vue"

export default {
  name: 'DynaList',
  
  components: {Input},
  
  props: {
    addText: {
      type: String,
      default: "Добавить",
    },
    withTitle: {
      type: Boolean,
      default: false,
    },
    apiDomain: String,
    apiTopic: String,
    baseUrl: {
      type: String,
    },
    panelStyle: {
      type: Object,
    }
  },
  
  data()
  {
    return {
      items: [],
    }
  },
  
  methods:
  {
    addItem()
    {
      darsan.post(this.apiDomain, this.apiTopic, this.baseUrl, {})
      .then(res =>
      {
        this.items.push(res)
      })
    },
    
    delItem(i)
    {
      darsan.delete(this.apiDomain, this.apiTopic, this.items[i].entity)
      .then(res =>
      {
        this.items.splice(i, 1)
      })
    },

  },
  
  watch: {
    baseUrl: {
      async handler()
      {
        if (!this.baseUrl) return
        this.items = await darsan.get(this.apiDomain, this.apiTopic, this.baseUrl)
      },
      immediate: true,
    },
  },

};
                                                              
</script>

<style scoped>

.mt-3
{
  margin-top: 0em;
}

.panel-body
{
  padding: 5px;
}
</style>
